<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar
      flat
      dense
      class="elevation-3"
      :class="{
        'sticky-xs': $vuetify.breakpoint.mdAndDown,
        'sticky-lg': $vuetify.breakpoint.lgAndUp
      }"
      color="secondary"
    >
      <v-btn
        v-if="backRoute.length > 0"
        small
        fab
        dark
        color="info"
        @click="$router.go(-1)"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-btn
        v-else
        small
        fab
        dark
        color="info"
        @click="this.$router.back()"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.params.id == -1
          ? newRecordTitle
          : this.$route.meta.subtitle + " (#" + this.$route.params.id + ")"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="this.$route.params.id > 0"
        small
        fab
        dark
        color="error"
        @click.stop="deleteDialog = true"
        class="mx-1"
      >
        <v-icon dark>delete</v-icon>
      </v-btn>
      <v-btn small fab dark color="success" @click="saveRecord">
        <v-icon dark>save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text
      class="pt-12"
      :class="{ 'pl-0 pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-form>
        <v-progress-circular
          :size="50"
          color="secondary"
          indeterminate
          :class="{
            'loading-visible': this.loading,
            'loading-hidden': !this.loading
          }"
        ></v-progress-circular>

        <v-tabs v-model="tabs" show-arrows>
          <v-tab v-for="section in formData" :key="section.title">
            {{ section.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="section in formData"
            :key="section.title"
            :eager="true"
            transition="scroll-y-transition"
            reverse-transition="scroll-y-transition"
          >
            <v-card class="mt-4">
              <v-card-title class="subtitle-1 font-weight-bold">{{
                section.title
              }}</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row
                    v-for="row in section.rows"
                    :key="row.id"
                    dense
                    align-start
                    justify-center
                  >
                    <v-col
                      v-for="field in row.fields"
                      :key="field.name"
                      cols="12"
                      :sm="getFieldSize('sm', row.fields.length)"
                      :md="getFieldSize('md', row.fields.length)"
                      :lg="getFieldSize('lg', row.fields.length)"
                    >
                      <TextInput
                        v-if="field.type == 'text'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                        :disabled="field.disabled"
                        :initialValue="field.initialValue"
                      ></TextInput>
                      <OibInput
                        v-else-if="field.type == 'oib'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></OibInput>
                      <PhoneInput
                        v-else-if="field.type == 'phone'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></PhoneInput>
                      <DateInput
                        v-else-if="field.type == 'date'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                        :resetButton="field.resetButton"
                      ></DateInput>
                      <EmailInput
                        v-else-if="field.type == 'email'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></EmailInput>
                      <CountryInput
                        v-else-if="field.type == 'country'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></CountryInput>
                      <ToggleInput
                        v-else-if="field.type == 'toggle'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></ToggleInput>
                      <UserInput
                        v-else-if="field.type == 'user'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></UserInput>
                      <RoleInput
                        v-else-if="field.type == 'role'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></RoleInput>
                      <MembershipNameInput
                        v-else-if="field.type == 'membershipname'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></MembershipNameInput>
                      <SexInput
                        v-else-if="field.type == 'sex'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></SexInput>
                      <PersonalStatusInput
                        v-else-if="field.type == 'personalstatus'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></PersonalStatusInput>
                      <InvoiceInput
                        v-else-if="field.type == 'invoice'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></InvoiceInput>
                      <CompanyInput
                        v-else-if="field.type == 'company'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></CompanyInput>
                      <BoardInput
                        v-else-if="field.type == 'board'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></BoardInput>
                      <UserMembershipInfo
                        v-else-if="field.type == 'membershipinfo'"
                        :mtype="field.label"
                        :ucid="$route.params.id"
                      ></UserMembershipInfo>
                      <UserRoleUnitInput
                        v-else-if="field.type == 'userroleunit'"
                        :label="field.label"
                        :model.sync="formdata[field.name]"
                      ></UserRoleUnitInput>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-snackbar
        v-model="snackbar"
        right
        :timeout="3000"
        :color="this.snackbarType === 0 ? 'success' : 'error'"
        >{{ this.message }}</v-snackbar
      >
    </v-card-text>

    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-card-title class="title" primary-title
          >Potvrda brisanja</v-card-title
        >
        <v-card-text
          >Da li ste sigurni da želite brisati ovaj zapis?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="deleteRecord"
            >Slažem se</v-btn
          >

          <v-btn color="grey darken-1" text @click="deleteDialog = false"
            >Odustani</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import OibInput from "@/components/OibInput.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import DateInput from "@/components/DateInput.vue";
import EmailInput from "@/components/EmailInput.vue";
import CountryInput from "@/components/CountryInput.vue";
import ToggleInput from "@/components/ToggleInput.vue";
import UserInput from "@/components/UserInput.vue";
import RoleInput from "@/components/RoleInput.vue";
//import UnitInput from "@/components/UnitInput.vue";
import MembershipNameInput from "@/components/MembershipNameInput.vue";
import SexInput from "@/components/SexInput.vue";
import PersonalStatusInput from "@/components/PersonalStatusInput.vue";
import InvoiceInput from "@/components/InvoiceInput.vue";
import CompanyInput from "@/components/CompanyInput.vue";
import BoardInput from "@/components/BoardInput.vue";
import UserMembershipInfo from "@/components/UserMembershipInfo.vue";
import UserRoleUnitInput from "@/components/UserRoleUnitInput.vue";

export default {
  props: {
    formData: Array,
    apiActions: String,
    backRoute: String,
    newRecordTitle: String,
    listRefreshAction: String
  },
  data() {
    return {
      formdata: {},
      loading: false,
      snackbar: false,
      snackbarType: 0,
      message: "",
      deleteDialog: false,
      tabs: null
    };
  },
  components: {
    TextInput,
    OibInput,
    PhoneInput,
    DateInput,
    EmailInput,
    CountryInput,
    ToggleInput,
    UserInput,
    RoleInput,
    //UnitInput,
    MembershipNameInput,
    SexInput,
    PersonalStatusInput,
    InvoiceInput,
    CompanyInput,
    BoardInput,
    UserMembershipInfo,
    UserRoleUnitInput
  },
  mounted() {
    if (this.$route.params.id >= 0) {
      this.getDataFromApi().then(data => {
        this.formdata = data;
      });
    } else {
      for (var s = 0; s < this.formData.length; s++) {
        for (var r = 0; r < this.formData[s].rows.length; r++) {
          for (var f = 0; f < this.formData[s].rows[r].fields.length; f++) {
            this.formdata[this.formData[s].rows[r].fields[f].name] = !!this
              .formData[s].rows[r].fields[f].initialValue
              ? this.formData[s].rows[r].fields[f].initialValue
              : null;
          }
        }
      }
    }
  },
  methods: {
    getFieldSize(type, num) {
      var size = 12;
      switch (type) {
        case "sm":
          if (num > 2) num = 2;
          size = 12 / num;
        case "md":
          if (num > 3) num = 3;
          size = 12 / num;
          break;
        case "lg":
          if (num > 4) num = 4;
          size = 12 / num;
          break;
      }
      return size;
    },
    getDataFromApi() {
      var me = this;
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.apiActions, {
            params: {
              id: this.$route.params.id
            }
          })
          .then(response => {
            this.loading = false;
            resolve(response.data);
          })
          .catch(error => {
            this.loading = false;
            reject(error);
          });
      });
    },
    saveRecord() {
      // validate form !!!
      this.loading = true;
      if (this.$route.params.id >= 0) {
        // update
        this.$http
          .put(this.apiActions, {
            params: {
              form: this.formdata
            }
          })
          .then(response => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            if (response.data.success) {
              this.snackbarType = 0;
              this.message = "Podaci su uspješno spremljeni";
              this.$router.go(-1);
              this.$store.dispatch(this.listRefreshAction);
            } else {
              this.snackbarType = 1;
              this.message = "Greška u spremanju podataka";
            }
            this.snackbar = true;
          })
          .catch(error => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            this.snackbarType = 1;
            this.message = "Greška u spremanju podataka";
            this.snackbar = true;
          });
      } else {
        // insert
        this.$http
          .post(this.apiActions, {
            params: {
              form: this.formdata,
              params: this.$route.params
            }
          })
          .then(response => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            if (response.data.success) {
              this.snackbarType = 0;
              this.message = "Podaci su uspješno spremljeni";
              this.snackbar = true;
              this.$store.dispatch(this.listRefreshAction);
              this.$router.go(-1); // push(this.backRoute);
            } else {
              this.snackbarType = 1;
              this.message = "Greška u spremanju podataka";
              this.snackbar = true;
            }
          })
          .catch(error => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            this.snackbarType = 1;
            this.message = "Greška u spremanju podataka";
            this.snackbar = true;
          });
      }
    },
    deleteRecord() {
      this.loading = true;
      if (this.$route.params.id >= 0) {
        // delete
        this.$http
          .delete(this.apiActions, {
            params: {
              id: this.$route.params.id
            }
          })
          .then(response => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            if (response.data.success) {
              this.snackbarType = 0;
              this.message = "Zapis uspješno izbrisan";
              this.snackbar = true;
              this.$store.dispatch(this.listRefreshAction);
              this.$router.go(-1); //push(this.backRoute);
            } else {
              this.snackbarType = 1;
              this.message = "Greška u brisanju zapisa";
              this.snackbar = true;
            }
          })
          .catch(error => {
            this.loading = false;
            if (this.snackbar) this.snackbar = false;
            this.snackbarType = 1;
            this.message = "Greška u brisanju zapisa";
            this.snackbar = true;
          });
      }
    }
  }
};
</script>
