<template>
  <v-switch :label="label" v-model="fieldModelValue"></v-switch>
</template>
<script>
export default {
  props: {
    label: String,
    model: Boolean
  },
  data() {
    return {};
  },
  computed: {
    fieldModelValue: {
      get() {
        return this.model;
      },
      set(newValue) {
        this.$emit("update:model", newValue);
      }
    }
  }
};
</script>
