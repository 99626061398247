<template>
  <v-autocomplete
    v-model="fieldModelValue"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-items
    class="mx-4"
    hide-no-data
    hide-details
    :label="label"
    prepend-icon="card_membership"
  ></v-autocomplete>
</template>
<script>
import { filter } from "minimatch";
export default {
  props: {
    label: String,
    model: String
  },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selected: null,
      initValue: null
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        if (!this.initValue) {
          var modelprop = this.model;
          this.querySelections(modelprop);
        }
        return this.selected;
      },
      set(newValue) {
        this.selected = newValue;
        this.$emit("update:model", newValue);
      }
    }
  },
  watch: {
    search(val) {
      val &&
        (!this.fieldModelValue || val !== this.fieldModelValue) &&
        this.querySelections(val);
    }
  },
  methods: {
    querySelections(v) {
      this.loading = true;
      var me = this;
      this.$http
        .get("/membership/membershipnames.php", {
          params: {
            filter: v
          }
        })
        .then(response => {
          var ritems = response.data.rows;
          this.items = ritems;
          me.loading = false;
          if (!this.initValue && v) {
            this.selected = v;
            this.initValue = v;
            this.fieldModelValue = this.selected;
          }
        })
        .catch(error => {
          //reject(error);
          me.loading = false;
        });
    }
  }
};
</script>
