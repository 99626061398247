var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"apiActions":"role/role.php","backRoute":"/rolelist","newRecordTitle":"Nova uloga","listRefreshAction":"role_list_needs_refresh","formData":[
      {
        title: 'OSNOVNI PODACI',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Naziv',
                type: 'text',
                name: 'name'
              },
              {
                label: 'Opis',
                type: 'text',
                name: 'description'
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }