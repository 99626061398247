<template>
  <v-text-field
    :label="label"
    v-model="fieldModelValue"
    :rules="stringRules"
    :readonly="disabled"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    model: String,
    maxlength: Number,
    disabled: Boolean,
    initialValue: String
  },
  data() {
    return {
      fieldModelValue: "",
      maxLen: this.maxlength || 500,
      stringRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true
      ]
    };
  },
  watch: {
    fieldModelValue(val) {
      this.$emit("update:model", val);
    },
    model(val) {
      //if(val)
      this.fieldModelValue = val;
    }
  },
  // computed: {
  //       fieldModelValue: {
  //         get() {
  //           return this.model;
  //         },
  //         set(newValue) {
  //           this.$emit('update:model', newValue);
  //           console.log('set new value: '+this.label+":"+newValue);
  //         }
  //       }
  //     },
  mounted() {
    this.fieldModelValue = this.initialValue || null;
  }
};
</script>
