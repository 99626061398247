<template>
  <v-autocomplete
    v-model="fieldModelValue"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-items
    class="mx-4"
    hide-no-data
    hide-details
    :label="label"
    prepend-icon="people"
    item-value="id"
    item-text="first_last_name"
    return-object
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    label: String,
    model: String
  },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selected: null
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        //var modelprop = this.model;
        //var filtered = this.items.filter(function(c){return modelprop==c.id;});
        //return filtered ? filtered[0] : null;
        return this.selected;
      },
      set(newValue) {
        this.selected = newValue;
        this.$emit("update:model", newValue ? newValue.id : null);
      }
    }
  },
  watch: {
    search(val) {
      val &&
        (!this.fieldModelValue || val !== this.fieldModelValue.last_name) &&
        this.querySelections(val);
    },
    model(val) {
      if(val!=null)
       this.findUser(val);
    }
  },
  methods: {
    querySelections(v) {
      this.loading = true;
      var me = this;
      this.$http
        .get("/user/users.php", {
          params: {
            page: 0,
            page_size: 20,
            filter_column: "last_name",
            filter: v
          }
        })
        .then(response => {
          var ritems = response.data.rows;
          for (var i = 0; i < ritems.length; i++) {
            ritems[i].first_last_name =
              ritems[i].first_name + " " + ritems[i].last_name;
          }
          this.items = ritems;
          me.loading = false;
        })
        .catch(error => {
          //reject(error);
          me.loading = false;
        });
    },
    findUser(id) {
      this.loading = true;
      var me = this;
      this.$http
        .get("/user/user.php", {
          params: {
            id: id
          }
        })
        .then(response => {
          if(response.data!=null) {
            var item = {
                "id":response.data.id,
                "first_name":response.first_name,
                "last_name":response.last_name,
                "first_last_name":response.data.first_name+" "+response.data.last_name
              };
            me.items = [];
            me.items.push(item);
            me.fieldModelValue = item; 
          }
          else {
            me.fieldModelValue = null;
          }
          me.loading = false;
        })
        .catch(error => {
          me.loading = false;
        })
    },
  }
};
</script>
