<template>
  <v-text-field
    :label="label"
    v-model="fieldModelValue"
    :rules="stringRules"
    prepend-icon="email"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    model: String,
    maxlength: Number
  },
  data() {
    return {
      maxLen: this.maxlength || 500,
      stringRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^([a-zA-Z0-9-_]+|([a-zA-Z0-9-_]+\.){1,2}[a-zA-Z0-9-_]+)@([a-zA-Z0-9-_]+\.){1,3}[a-z]{2,}$/.test(
                v
              ) ||
              "E-mail nije ispravan"
            : true
      ]
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        return this.model;
      },
      set(newValue) {
        this.$emit("update:model", newValue);
      }
    }
  }
};
</script>
