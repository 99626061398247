<template>
  <div class="mx-4">
    <v-text-field
      readonly
      label="Org. jedinica"
      prepend-icon="folder_shared"
      :value="
        fieldModelValue ? fieldModelValue.name : 'Odaberite org. jedinicu'
      "
      @click="visible = !visible"
    ></v-text-field>
    <v-progress-circular
      v-if="loading"
      class="progress"
      indeterminate
    ></v-progress-circular>
    <v-slide-y-transition>
      <v-sheet v-show="visible" height="200" width="400" dark class="treeview">
        <v-treeview
          :items="items"
          dense
          open-all
          rounded
          activatable
          :active.sync="active"
          return-object
          color="info"
        ></v-treeview>
      </v-sheet>
    </v-slide-y-transition>
  </div>
</template>
<script>

export default {
  props: {
    label: String,
    model: {Object,String},
    parentCompany: Number,
    returnObject: Boolean
  },
  data() {
    return {
      loading: false,
      items: [],
      fieldModelValue: { id: -1, name: "" },
      active: [],
      visible: false,
      needUpdateModel: false
    };
  },
  watch: {
    fieldModelValue(val) {
      if(this.needUpdateModel) {
        if(this.returnObject)
          this.$emit("update:model", val!=null ? val : null);
        else
          this.$emit("update:model", val!=null ? val.id : null);
      }
    },
    model(val) {
      if (val!=null) {
        this.needUpdateModel = false;
        if(this.returnObject)
          //this.fieldModelValue =  val;
          this.findUnit(val.id);
        else
          this.findUnit(val);
      }
    },
    parentCompany(val, oldval) {
      this.needUpdateModel = true;
      if (oldval) this.fieldModelValue = null;
      if (val) {
        this.querySelections();
      }
    },
    active: {
      handler() {
        this.needUpdateModel = true;
        this.fieldModelValue = this.active[0];
        this.visible = false;
      },
      deep: true
    }
  },
  methods: {
    querySelections() {
      this.loading = true;
      var me = this;
      this.$http
        .get("/unit/companyunits.php", {
          params: {
            parent_company: me.parentCompany
          }
        })
        .then(response => {
          me.items = response.data;
          me.loading = false;   
          if (me.items && me.fieldModelValue && !this.findItem(me.items,me.fieldModelValue.id))
            fieldModelValue = null;
        })
        .catch(error => {
          //reject(error);
          me.loading = false;
        });
    },
    findUnit(id) {
      this.loading = true;
      var me = this;
      this.$http
        .get("/unit/unit.php", {
          params: {
            id: id
          }
        })
        .then(response => {
          me.fieldModelValue = response.data;
          me.loading = false;
        })
        .catch(error => {
          me.loading = false;
        })
    },
    findItem(itemlist,val) {
      var fnd = false;
      for (var i = 0; i < itemlist.length; i++) {
        if (itemlist[i].id == val) {
          return true;
        } else if (itemlist[i].children) {
          if(this.findItem(itemlist[i].children,val))
            return true;
        }
      }
      return false;
    }
  }
};
</script>

<style scoped>
.treeview {
  overflow-y: scroll;
  scrollbar-color: orange;
  position: absolute;
  z-index: 1001;
}
.progress {
  opacity: 0.7;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}
</style>
