<template>
  <div>
    <div v-if="this.$router.currentRoute.params.id != -1">
      <v-btn
        small
        fab
        dark
        absolute
        top
        right
        class="mx-1"
        color="accent"
        @click="addRow"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
      <InteractiveTable
        ref="boardList"
        class="xl-table"
        :headers="[
          { text: 'ID', value: 'id' },
          { text: 'Kratica odbora', value: 'short_name' },
          { text: 'Odbor', value: 'name' },
          { text: 'Funkcija', value: 'role_name' },
          { text: 'Vrijedi od', value: 'valid_from' },
          { text: 'Vrijedi do', value: 'valid_to' }
        ]"
        :apiLoad="
          'board/userboards.php?user='.concat(
            this.$router.currentRoute.params.id
          )
        "
        :actions="[
          {
            id: '1',
            name: 'Uredi',
            eventCall: 'editRow',
            icon: 'edit',
            color: 'secondary'
          },
          {
            id: '2',
            name: 'Briši',
            eventCall: 'deleteRow',
            icon: 'delete_forever',
            color: 'error'
          }
        ]"
        defaultFilter="name"
        :model.sync="fieldModelValue.rows"
        @editRow="editRow"
        @deleteRow="openDeleteDialog"
        defaultSort="valid_from"
        defaultSortDesc
      ></InteractiveTable>
      <v-dialog v-model="dialogEdit" width="500" :eager="true">
        <v-card>
          <v-card-title>Uređivanje podataka o odboru</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <BoardSelectInput
                    label="Odbor"
                    :model.sync="formData['board']"
                  ></BoardSelectInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RoleInput
                    label="Funkcija"
                    :model.sync="formData['role']"
                  ></RoleInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <DateInput
                    label="Vrijedi od"
                    :model.sync="formData['valid_from']"
                    resetButton
                  ></DateInput>
                </v-col>
                <v-col cols="12" sm="6">
                  <DateInput
                    label="Vrijedi do"
                    :model.sync="formData['valid_to']"
                    resetButton
                  ></DateInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <TextInput
                    label="Napomena"
                    :model.sync="formData['note']"
                  ></TextInput>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="editMode == 1"
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="addRowToTable"
              >DODAJ</v-btn
            >
            <v-btn
              v-if="editMode == 2"
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="editRowInTable"
              >SPREMI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogEdit = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text>
            Jeste li sigurni da želite izbrisati članstvo?<br />
            Obično je bolje samo postaviti datume važenja.
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="deleteRow"
              >BRIŠI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogDelete = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else class="subtitle-2">
      Prvo spremite ovog korisnika u bazu kako bi mogli uređivati članstvo u
      odborima.
    </div>
  </div>
</template>
<script>
import InteractiveTable from "../components/InteractiveTable";
import TextInput from "../components/TextInput";
import BoardSelectInput from "../components/BoardSelectInput";
import DateInput from "../components/DateInput";
import RoleInput from "../components/RoleInput";

export default {
  props: {
    label: String,
    model: Object
  },
  components: {
    InteractiveTable,
    TextInput,
    BoardSelectInput,
    DateInput,
    RoleInput
  },
  data() {
    return {
      loading: false,
      search: null,
      selected: null,
      editMode: 1,
      dialogEdit: false,
      formData: {
        user_id: this.$router.currentRoute.params.id,
        board: {},
        role: {},
        valid_from: null,
        valid_to: null,
        note: null
      },
      editingRow: null,
      fieldModelValue: {
        rows: [],
        deletedRows: []
      },
      dialogDelete: false,
      nextId: -1
    };
  },
  watch: {
    fieldModelValue: {
      handler() {
        this.$emit("update:model", this.fieldModelValue);
      },
      deep: true
    },
    model(val) {
      if (val) this.fieldModelValue = val;
    }
  },
  methods: {
    addRow() {
      this.editMode = 1;
      this.dialogEdit = true;
    },
    editRow(sel) {
      this.editMode = 2;
      this.formData.board = {
        id: sel.board_id,
        short_name: sel.short_name,
        name: sel.name
      };
      this.formData.role = {
        id: sel.role_id,
        name: sel.role_name
      };
      this.formData.valid_from = sel.valid_from;
      this.formData.valid_to = sel.valid_to;
      this.formData.note = sel.note;
      this.editingRow = sel;
      this.dialogEdit = true;
    },
    deleteRow() {
      // add to deleted list
      if (this.editingRow.id != -1)
        this.fieldModelValue.deletedRows.push(this.editingRow);
      for (var i = 0; i < this.fieldModelValue.rows.length; i++) {
        if (this.fieldModelValue.rows[i].id == this.editingRow.id) {
          this.fieldModelValue.rows.splice(i, 1);
          break;
        }
      }
      this.dialogDelete = false;
    },
    openDeleteDialog(sel) {
      this.editingRow = sel;
      this.dialogDelete = true;
    },
    addRowToTable() {
      var newrow = {
        id: this.nextId--,
        short_name: this.formData.board.short_name,
        name: this.formData.board.name,
        role_name: this.formData.role.name,
        valid_from: this.formData.valid_from,
        valid_to: this.formData.valid_to,
        board_id: this.formData.board.id,
        role_id: this.formData.role.id,
        user_id: this.$router.currentRoute.params.id,
        note: this.formData.note,
        action: "A"
      };

      this.fieldModelValue.rows.push(newrow);

      this.dialogEdit = false;
    },
    editRowInTable() {
      this.editingRow.short_name = this.formData.board.short_name;
      this.editingRow.name = this.formData.board.name;
      this.editingRow.role_name = this.formData.role.name;
      this.editingRow.valid_from = this.formData.valid_from;
      this.editingRow.valid_to = this.formData.valid_to;
      this.editingRow.board_id = this.formData.board.id;
      this.editingRow.role_id = this.formData.role.id;
      this.editingRow.note = this.formData.note;
      if (this.editingRow.id > 0) this.editingRow.action = "E";
      this.dialogEdit = false;
    }
  }
};
</script>
