<template>
  <v-scale-transition>
    <BaseForm
      apiActions="role/role.php"
      backRoute="/rolelist"
      newRecordTitle="Nova uloga"
      listRefreshAction="role_list_needs_refresh"
      :formData="[
        {
          title: 'OSNOVNI PODACI',
          rows: [
            {
              id: 1,
              fields: [
                {
                  label: 'Naziv',
                  type: 'text',
                  name: 'name'
                },
                {
                  label: 'Opis',
                  type: 'text',
                  name: 'description'
                }
              ]
            }
          ]
        }
      ]"
    ></BaseForm>
  </v-scale-transition>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";

export default {
  data() {
    return {};
  },
  components: {
    BaseForm
  }
};
</script>
