<template>
  <v-text-field
    :label="label"
    v-model="fieldModelValue"
    :rules="stringRules"
    prepend-icon="phone"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    model: String
  },
  data() {
    return {
      stringRules: [
        v =>
          v
            ? v.length == 0 ||
              v.length <= 30 ||
              "Maksimalni dozvoljeni broj znakova je 30"
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^[0-9\+\- ]+$/.test(v) ||
              "Neispravan broj telefona."
            : true
      ]
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        return this.model;
      },
      set(newValue) {
        this.$emit("update:model", newValue);
      }
    }
  }
};
</script>
