<template>
  <div>
    <div v-if="this.$router.currentRoute.params.id != -1">
      <v-btn
        small
        fab
        dark
        absolute
        top
        right
        class="mx-1"
        color="accent"
        @click="addRow"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
      <InteractiveTable
        ref="userRoleUnitList"
        class="xl-table"
        :headers="[
          { text: 'ID', value: 'id' },
          { text: 'Poduzeće', value: 'company_name' },
          { text: 'Org. jedinica', value: 'unit_name' },
          { text: 'Funkcija', value: 'role_name' },
          { text: 'Vrijedi od', value: 'valid_from' },
          { text: 'Vrijedi do', value: 'valid_to' }
        ]"
        :apiLoad="
          'userroleunit/userroleunits.php?user='.concat(
            this.$router.currentRoute.params.id
          )
        "
        :actions="[
          {
            id: '1',
            name: 'Uredi',
            eventCall: 'editRow',
            icon: 'edit',
            color: 'secondary'
          },
          {
            id: '2',
            name: 'Briši',
            eventCall: 'deleteRow',
            icon: 'delete_forever',
            color: 'error'
          }
        ]"
        defaultFilter="company_name"
        :model.sync="fieldModelValue.rows"
        @editRow="editRow"
        @deleteRow="openDeleteDialog"
        defaultSort="valid_from"
        defaultSortDesc
      ></InteractiveTable>
      <v-dialog v-model="dialogEdit" width="500" :eager="true">
        <v-card>
          <v-card-title>Uređivanje podataka o org. jedinici</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <CompanyInput
                    label="Poduzeće"
                    :model.sync="formData['company']"
                    returnObject
                  ></CompanyInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <UnitInput
                    label="Org. jedinica"
                    :model.sync="formData['unit']"
                    :parentCompany="Number(formData.company.id)"
                    returnObject
                  ></UnitInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <RoleInput
                    label="Funkcija"
                    :model.sync="formData['role']"
                  ></RoleInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <DateInput
                    label="Vrijedi od"
                    :model.sync="formData['valid_from']"
                    resetButton
                  ></DateInput>
                </v-col>
                <v-col cols="12" sm="6">
                  <DateInput
                    label="Vrijedi do"
                    :model.sync="formData['valid_to']"
                    resetButton
                  ></DateInput>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <TextInput
                    label="Napomena"
                    :model.sync="formData['note']"
                  ></TextInput>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="editMode == 1"
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="addRowToTable"
              >DODAJ</v-btn
            >
            <v-btn
              v-if="editMode == 2"
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="editRowInTable"
              >SPREMI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogEdit = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text>
            Jeste li sigurni da želite izbrisati pripadnost org. jedinici?<br />
            Obično je bolje samo postaviti datume važenja.
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="deleteRow"
              >BRIŠI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogDelete = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else class="subtitle-2">
      Prvo spremite ovog korisnika u bazu kako bi mogli uređivati poduzeće u
      kojem je zaposlen.
    </div>
  </div>
</template>
<script>
import InteractiveTable from "../components/InteractiveTable";
import TextInput from "../components/TextInput";
import CompanyInput from "../components/CompanyInput";
import DateInput from "../components/DateInput";
import RoleInput from "../components/RoleInput";
import UnitInput from "../components/UnitInput";

export default {
  props: {
    label: String,
    model: Object
  },
  components: {
    InteractiveTable,
    TextInput,
    CompanyInput,
    DateInput,
    RoleInput,
    UnitInput
  },
  data() {
    return {
      loading: false,
      search: null,
      selected: null,
      editMode: 1,
      dialogEdit: false,
      formData: {
        id: null,
        user: this.$router.currentRoute.params.id,
        company: {},
        role: {},
        unit: {},
        valid_from: null,
        valid_to: null,
        note: null
      },
      editingRow: null,
      fieldModelValue: {
        rows: [],
        deletedRows: []
      },
      dialogDelete: false,
      nextId: -1
    };
  },
  watch: {
    fieldModelValue: {
      handler() {
        this.$emit("update:model", this.fieldModelValue);
      },
      deep: true
    },
    model(val) {
      if (val) this.fieldModelValue = val;
    }
  },
  methods: {
    addRow() {
      this.editMode = 1;
      this.dialogEdit = true;
    },
    editRow(sel) {
      this.editMode = 2;
      this.formData.id = sel.id;
      this.formData.company = {
        id: sel.company,
        name: sel.company_name
      };
      this.formData.role = {
        id: sel.role,
        name: sel.role_name
      };
      this.formData.unit = {
        id: sel.unit,
        name: sel.unit_name
      };
      this.formData.valid_from = sel.valid_from;
      this.formData.valid_to = sel.valid_to;
      this.formData.note = sel.note;
      this.editingRow = sel;
      this.dialogEdit = true;
    },
    deleteRow() {
      // add to deleted list
      if (this.editingRow.id != -1)
        this.fieldModelValue.deletedRows.push(this.editingRow);
      for (var i = 0; i < this.fieldModelValue.rows.length; i++) {
        if (this.fieldModelValue.rows[i].id == this.editingRow.id) {
          this.fieldModelValue.rows.splice(i, 1);
          break;
        }
      }
      this.dialogDelete = false;
    },
    openDeleteDialog(sel) {
      this.editingRow = sel;
      this.dialogDelete = true;
    },
    addRowToTable() {
      if (this.formData.company && this.formData.unit && this.formData.role) {
        var newrow = {
          id: this.nextId--,
          company_name: this.formData.company.name,
          role_name: this.formData.role.name,
          unit_name: this.formData.unit.name,
          valid_from: this.formData.valid_from,
          valid_to: this.formData.valid_to,
          company: this.formData.company.id,
          role: this.formData.role.id,
          user: this.$router.currentRoute.params.id,
          unit: this.formData.unit.id,
          note: this.formData.note,
          action: "A"
        };

        this.fieldModelValue.rows.push(newrow);

        this.dialogEdit = false;
      }
    },
    editRowInTable() {
      if (this.formData.company && this.formData.unit && this.formData.role) {
        this.editingRow.company_name = this.formData.company.name;
        this.editingRow.role_name = this.formData.role.name;
        this.editingRow.unit_name = this.formData.unit.name;
        this.editingRow.valid_from = this.formData.valid_from;
        this.editingRow.valid_to = this.formData.valid_to;
        this.editingRow.company = this.formData.company.id;
        this.editingRow.role = this.formData.role.id;
        this.editingRow.unit = this.formData.unit.id;
        this.editingRow.note = this.formData.note;
        if (this.editingRow.id > 0) this.editingRow.action = "E";
        this.dialogEdit = false;
      }
    }
  }
};
</script>
