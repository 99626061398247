<template>
  <v-container fluid class="pt-0 pb-2">
    <v-row>
      <v-col cols="12" class="py-2">
        <span class="mr-4 sclass">{{ membershipName + ":" }}</span>
        <v-chip
          class="mx-1"
          v-for="item in items"
          :key="item.year"
          :color="item.payment_date ? 'green lighten-1' : 'red lighten-1'"
          dark
        >
          {{
            item.year +
              ". " +
              (item.payment_date
                ? item.amount +
                  (item.year>=2023 ? "EUR " : "HRK ") +
                  formatDate(item.payment_date) +
                  " izvod:" +
                  item.statement_num
                : "nije plaćeno")
          }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  props: {
    ucid: String,
    mtype: String
  },
  data() {
    return {
      items: []
    };
  },
  computed: {
    membershipName() {
      var textType = "";
      switch (this.mtype) {
        case "I":
          textType = "Plaćanje ind. članarine";
          break;
        case "MI":
          textType = "Plaćanje ind. međ. članarine";
          break;
        case "K":
          textType = "Plaćanje kol. članarine";
          break;
        case "MK":
          textType = "Plaćanje kol. međ. članarine";
          break;
      }
      return textType;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY.");
    }
  },
  mounted() {
    this.$http
      .get("membership/payment.php", {
        params: {
          id: this.ucid,
          type: this.mtype
        }
      })
      .then(response => {
        this.items = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped>
.sclass {
  min-width: 180px;
  display: inline-block;
}
</style>
