<template>
  <v-select
    v-model="fieldModelValue"
    :items="sex"
    item-text="name"
    item-value="code"
    menu-props="auto"
    :label="label"
    prepend-icon="map"
    return-object
  ></v-select>
</template>
<script>
export default {
  props: {
    label: String,
    model: String
  },
  data() {
    return {
      sex: [
        { name: "Muško", code: "0" },
        { name: "Žensko", code: "1" }
      ]
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        var modelprop = this.model;
        var filtered = this.sex.filter(function(c) {
          return modelprop == c.code;
        });
        return filtered ? filtered[0] : null;
      },
      set(newValue) {
        this.$emit("update:model", newValue.code);
      }
    }
  }
};
</script>
