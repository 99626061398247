<template>
  <v-text-field
    v-if="dense"
    :label="label"
    v-model="fieldModelValue"
    :rules="stringRules"
    dense
  ></v-text-field>
  <v-text-field
    v-else
    :label="label"
    v-model="fieldModelValue"
    :rules="stringRules"
    prepend-icon="person_pin"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    model: String,
    dense: Boolean,
    required: Boolean
  },
  data() {
    return {
      stringRules: [
        v => !!v || !this.required || "OIB je obavezan podatak",
        v =>
          v
            ? v.length == 0 ||
              v.length == 11 ||
              "OIB mora sadržavati 11 znamenki"
            : true,
        v => {
          if (v && v.length > 0) {
            v = v.toString();
            if (v.length != 11) return false;

            var a = 10;
            for (var i = 0; i < 10; i++) {
              a = a + parseInt(v.substr(i, 1), 10);
              a = a % 10;
              if (a == 0) a = 10;
              a *= 2;
              a = a % 11;
            }
            var kontrolni = 11 - a;
            if (kontrolni == 10) kontrolni = 0;

            return (
              kontrolni == parseInt(v.substr(10, 1)) || "OIB nije ispravan"
            );
          } else return true;
        }
      ]
    };
  },
  computed: {
    fieldModelValue: {
      get() {
        return this.model;
      },
      set(newValue) {
        this.$emit("update:model", newValue);
      }
    }
  }
};
</script>
