<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="slide-x-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-container fluid>
        <v-row dense class="align-center">
          <v-col cols="10">
            <v-text-field
              v-model="computedDateFormatted"
              :label="label"
              prepend-icon="date_range"
              :dense="dense"
              readonly
              v-on="on"
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="resetButton"
              small
              icon
              class="mx-1"
              color="error"
              @click="fieldModelValue = null"
              ><v-icon>cancel</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-date-picker
      v-model="fieldModelValue"
      @input="menuVisible = false"
      locale="hr-HR"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";

export default {
  props: {
    label: String,
    model: String,
    resetButton: Boolean,
    required: Boolean,
    dense: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      fieldModelValue: "",
      menuVisible: false,
      requiredRules: [v => !!v || !this.required || "Ovo je obavezan podatak"]
    };
  },
  watch: {
    fieldModelValue(val) {
      this.$emit("update:model", val);
    },
    model(val) {
      this.fieldModelValue = val;
    }
  },
  computed: {
    // fieldModelValue: {
    //   get() {
    //     return this.model;
    //   },
    //   set(newValue) {
    //     this.$emit("update:model", newValue);
    //   }
    // },
    computedDateFormatted() {
      return this.fieldModelValue
        ? moment(this.fieldModelValue).format("DD.MM.YYYY.")
        : "";
    }
  }
};
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
